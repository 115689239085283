import './App.css';
import { ThemeProvider } from 'styled-components';
import { connectorTheme as theme } from 'akeneo-design-system';
import SettingsPage from './page/SettingsPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10 * 1000, // 10s
        cacheTime: 5 * 60 * 1000, // 5m
        retry: 0,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route index element={<SettingsPage />} /> 
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
