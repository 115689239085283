import { useQuery } from 'react-query';
import { SettingsError, SettingsErrorData } from '../model/Error';
import { Settings } from '../model/Settings';

export const useFetchSettings = (callback?: (settings: Settings) => void) => {
  const url = process.env.REACT_APP_API_URL ?? '';

  return useQuery<Settings, SettingsError<SettingsErrorData>, Settings>(['settings'], async () => {
    const response = await fetch(url);

    if(!response.ok) {
      const error: SettingsErrorData = await response.json();
      throw new SettingsError(error);
    } 
    const settings = await response.json();
      if(callback) {
        callback(settings);
      }
      return settings;
  });     
};
