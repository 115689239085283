export interface SettingsErrorMessage {
  message: string;
}

export interface SettingsErrorData {
  errors: Array<SettingsErrorMessage>
}

export class SettingsError<FetchSettingsErrorData> extends Error {
  constructor(public readonly errorData: FetchSettingsErrorData) {
    super('Error while during call');
    Object.setPrototypeOf(this, SettingsError.prototype);
  }
}