import { useMutation } from 'react-query';
import { Settings } from '../model/Settings';
import { SettingsError, SettingsErrorData } from '../model/Error';

export const useSaveSettings = (callback?: () => void) => {
  const url = process.env.REACT_APP_API_URL ?? '';

  return useMutation<void, SettingsError<SettingsErrorData>, Settings>(async (settings: Settings) => {
    const response = await fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(settings)
      }
    );

    if(!response.ok) {
      const error: SettingsErrorData = await response.json();
      throw new SettingsError(error)
    }

    if(callback) {
      callback();
    }
  });     
};