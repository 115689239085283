import { useQuery } from 'react-query';
import { SettingsError, SettingsErrorData } from '../model/Error';

export const useFetchInstallationLink = () => {
  const url = `${process.env.REACT_APP_API_URL ?? ''}/yotpo_installation_link`;

  return useQuery<string, SettingsError<SettingsErrorData>, string>(['yotpo_installation_link'], async () => {
    const response = await fetch(url);

    if(!response.ok) {
      const error: SettingsErrorData = await response.json();
      throw new SettingsError(error);
    } 
    const installationLink = await response.json();
      
      return installationLink;
  });     
};
