import {
  Breadcrumb,
  Button,
  Placeholder,
  PlusIcon,
  SectionTitle,
  UsersIllustration
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import styled, { createGlobalStyle } from 'styled-components';
import { PageContent, PageHeader, Section } from '../component/page';
import { useDeleteSettings } from '../hooks/useDeleteSettings';
import { useFetchInstallationLink } from '../hooks/useFetchInstallationLink';
import { useFetchSettings } from '../hooks/useFetchSettings';
import { useSaveSettings } from '../hooks/useSaveSettings';
import { useSyncData } from '../hooks/useSyncData';
import { StoreSettings } from '../model/Settings';
import SettingsErrorMessage from './SettingsErrorMessage';
import SettingsForm from './SettingsForm';

const LAST_SYNC_DATA_CLICK_TIME_KEY = "lastSyncDataClickTime";
const SettingsPage: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentSetting, setCurrentSettings] = useState<StoreSettings[]>([]);

  const queryClient = useQueryClient();
  const {isError: isErrorFetching, error: errorFetching} = useFetchSettings((settings) => {
    if(settings.stores) {
      setCurrentSettings(settings.stores);
    }
  });
  const {data: installationLink, isError: isErrorInstallationLink, error: errorInstallationLink} = useFetchInstallationLink();
  const {mutate: saveSettings, isError: isErrorSaving, error: errorSaving} = useSaveSettings(() => queryClient.invalidateQueries(['settings']));
  const {mutate: deleteSettings, isError: isErrorDeleting, error: errorDeleting} = useDeleteSettings(() => queryClient.invalidateQueries(['settings']));
  const {mutate: syncData, isError: isErrorSyncData, error: errorSyncData} = useSyncData();

  const handleSetSettingsValue = (index: number, key: string, newValue: string) => {
      const newSettings = [...currentSetting];
      newSettings[index][key as keyof StoreSettings] = newValue;
      setCurrentSettings(newSettings);
  }

  const removeStoreSettingsClick = (index: number) => {   
    let newSettings = [...currentSetting]; 
    newSettings.splice(index, 1);
    setCurrentSettings(newSettings);
  };

  const handleSaveClick = () => {
    if(currentSetting) {
      saveSettings({
        stores: currentSetting
      });
    }
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setCurrentSettings([]);
    deleteSettings();
  };

  const handleSyncDataClick = () => {
    syncData();
    sessionStorage.setItem(LAST_SYNC_DATA_CLICK_TIME_KEY, new Date().toISOString());
  };

  const getTimeIntervalFromNow = (date: Date): number => {
    const now = new Date();
    return Math.abs(now.getTime() - date.getTime());
  };
  const TEN_MINUTES = 600000;
  const lastSyncDataClickTime = sessionStorage.getItem(LAST_SYNC_DATA_CLICK_TIME_KEY)
  const syncDataButtonDisabled = (lastSyncDataClickTime) ? getTimeIntervalFromNow(new Date(lastSyncDataClickTime)) < TEN_MINUTES: false;
  
  return (
    <>
    <GlobalStyle />
    <PageHeader>
      <PageHeader.Breadcrumb>
        <Breadcrumb>
          <Breadcrumb.Step>Settings</Breadcrumb.Step>
          <Breadcrumb.Step>API Credentials</Breadcrumb.Step>
        </Breadcrumb>
      </PageHeader.Breadcrumb>
      <PageHeader.Title>Yotpo Connector</PageHeader.Title>
      <PageHeader.Actions>
      { isEditing ? (
        <>
          <Button onClick={handleSaveClick}>Save</Button>
          <Button onClick={() => setIsEditing(false)} ghost>Cancel</Button>
        </>
      ) : (
        <>
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
          {currentSetting && (
            <Button level="danger" onClick={handleDeleteClick}>Delete</Button>
          )}
        </>
      )}
      <Button level='secondary' onClick={handleSyncDataClick} disabled={syncDataButtonDisabled}>Sync Reviews</Button>
      </PageHeader.Actions>
    </PageHeader>
    <PageContent>
      <Section>
        <SectionTitle>
          <SectionTitle.Title>API Credentials</SectionTitle.Title>
          <SectionTitle.Spacer />
          <SectionTitle.Separator />
          {installationLink && (
            <Button             
              ghost={false} 
              href={installationLink}>
                Add a store <PlusIcon />
            </Button>  
          )}
        </SectionTitle>
        <Form>
          <SettingsErrorMessage isError={isErrorFetching} error={errorFetching} />
          <SettingsErrorMessage isError={isErrorInstallationLink} error={errorInstallationLink} />
          <SettingsErrorMessage isError={isErrorSaving} error={errorSaving} />
          <SettingsErrorMessage isError={isErrorDeleting} error={errorDeleting} />
          <SettingsErrorMessage isError={isErrorSyncData} error={errorSyncData} />

          { currentSetting.length > 0 ? (
            <>  
              {currentSetting.map((settings, index) => (           
                <SettingsForm 
                  key={index}
                  index={index} 
                  settings={settings}
                  readOnly={!isEditing}
                  handleSetSettingsValue={handleSetSettingsValue}
                  removeStoreSettingsClick={removeStoreSettingsClick} />
              ))}
            </>
          ) : (
            <>
              <Placeholder
                illustration={<UsersIllustration />}
                title="No Settings configured"
              >
              </Placeholder>
            </>
          )
        }
        </Form>
      </Section>
    </PageContent>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    left:0;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  * {
    box-sizing: border-box;
  }
`;

const Form = styled.form`
  margin-bottom: 80px;
`;

export default SettingsPage;
