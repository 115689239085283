import {
  DangerIcon,
  MessageBar
} from 'akeneo-design-system';
import { FunctionComponent } from 'react';
import { SettingsError, SettingsErrorData } from '../model/Error';

type Props = {
  isError: boolean;
  error: SettingsError<SettingsErrorData>|null;
};

const SettingsErrorMessage: FunctionComponent<Props> = ({isError, error}) => {
  return (
    <>
    {isError && error && (
            <MessageBar
            icon={<DangerIcon />}
            level="error"
            onClose={function noRefCheck(){}}
            title="Errors"
            dismissTitle='dismiss'
            >
              <>
              { error.errorData ? (
                <>
                  {error.errorData?.errors.map(error => (
                    <>
                      {error.message}
                    </>
                  ))}
                </>
              ) : (
                <>
                  Unexpected Error
                </>
              )}  
              </>
            </MessageBar>
          )}
    </>
  );
};

export default SettingsErrorMessage;
