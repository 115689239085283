import {
  DeleteIcon,
  Field,
  IconButton,
  TextInput
} from 'akeneo-design-system';
import { FunctionComponent } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { useDeleteSettingsByClientId } from '../hooks/useDeleteSettingsByClientId';
import { StoreSettings } from '../model/Settings';
import SettingsErrorMessage from './SettingsErrorMessage';

type Props = {
  index: number;
  settings: StoreSettings;
  readOnly: boolean;
  handleSetSettingsValue: (index: number, key: string, newValue: string) => void;
  removeStoreSettingsClick: (index: number) => void;
};

  const SettingsForm: FunctionComponent<Props> = ({index, settings, readOnly, handleSetSettingsValue, removeStoreSettingsClick}) => {

    const queryClient = useQueryClient();
    const {mutate: deleteSettingsByClientId, isError, error} = useDeleteSettingsByClientId(() => queryClient.invalidateQueries(['settings']));
    const handleDeleteStoreSettingsClick = (index: number, clientId: string) => {   
      removeStoreSettingsClick(index);
      deleteSettingsByClientId(clientId);
    };
  
  return (
    <>
      <SettingsErrorMessage isError={isError} error={error} />          
      <div>
        <span>Storage settings for client ID : </span>
        <strong>{settings.client_id || '[Client ID]'}</strong>
      </div>
      <FieldSet>
        <FieldRow>
          <Field label="Client Id">
            <TextInput
              aria-labelledby="Client ID"
              placeholder="Enter your Yotpo Client ID"
              type="text"
              value={settings.client_id}
              onChange={(newValue: string) => handleSetSettingsValue(index, 'client_id', newValue)}
              readOnly={readOnly}
            />                             
          </Field>
          <Field label="Client Secret">
            <TextInput
              aria-labelledby="Client Secret"
              placeholder="Enter your Yotpo Client Secret"
              type="password"
              value={settings.client_secret}
              onChange={(newValue: string) => handleSetSettingsValue(index, 'client_secret', newValue)}
              readOnly={readOnly}
            />                             
          </Field>          
          <Field label="Token">
            <TextInput
              aria-labelledby="Token"
              placeholder="Enter your Token"
              type="password"
              value={settings.token}
              onChange={(newValue: string) => handleSetSettingsValue(index, 'token', newValue)}
              readOnly={readOnly}
            />                             
          </Field>
          <>
          { !readOnly && (
              <IconButton 
                title='Delete'
                icon={<DeleteIcon />} 
                ghost='borderless'
                level='tertiary' 
                onClick={() => handleDeleteStoreSettingsClick(index, settings.client_id)} />
            )}
          </>
        </FieldRow>
      </FieldSet>
    </>
  );
};

const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
`;

const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;


export default SettingsForm;
