import { useMutation } from 'react-query';
import { SettingsError, SettingsErrorData } from '../model/Error';

export const useDeleteSettings = (callback?: () => void) => {
  const url = process.env.REACT_APP_API_URL ?? '';

  return useMutation<void, SettingsError<SettingsErrorData>, void>(async () => {
    const response = await fetch(url,
      {
        method: 'DELETE',
      }
    );

    if(!response.ok) {
      const error: SettingsErrorData = await response.json();
      throw new SettingsError(error)
    }

    if(callback) {
      callback();
    }
  });     
};