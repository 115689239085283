import { useMutation } from 'react-query';
import { SettingsError, SettingsErrorData } from '../model/Error';

export const useSyncData = () => {
  return useMutation<any, SettingsError<SettingsErrorData>, void>(async () => {
    const url = `${process.env.REACT_APP_API_URL ?? ''}/sync_data`;
    const response = await fetch(url,
      {
        method: 'POST',
      }
    );

    if(!response.ok) {
      const error: SettingsErrorData = await response.json();      
      throw new SettingsError(error)
    }
  });     
};